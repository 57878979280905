// JS FRONT OFFICE
/*
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css'

flatpickr.localize(French);
window.flatpickr = flatpickr;
*/

window.showMessage = showMessage;
window.afficheOnglet = afficheOnglet;
window.hashchange = hashchange;
window.remplirProjetsEtTags = remplirProjetsEtTags;
window.planning_RAZ = planning_RAZ;

// INPUT FILE CUSTOM
var inputFileItems = document.querySelectorAll(".input-file");

for (var i=0; i < inputFileItems.length; i++ ) {
    var fileInput  = inputFileItems[i].children[0],
        button     = inputFileItems[i].children[1],
        the_return = inputFileItems[i].children[2];

    button.addEventListener( "keydown", function( event ) {
        if ( event.keyCode == 13 || event.keyCode == 32 ) {
            this.previousElementSibling.focus();
        }
    });

    button.addEventListener( "click", function( event ) {
        this.previousElementSibling.focus();
        return false;
    });

    fileInput.addEventListener( "change", function( event ) {
        var this_return = this.nextElementSibling.nextElementSibling;
        this_return.innerHTML = this.value.replace('C:\\fakepath\\' , ' ');
    });
} // END

var changement = false;

// Fonction qui affiche une notification en bas du header pendant 3 secondes
// type : ( string ) class qu'aura l'élément ajouté ( valid, error ou delete )
// message : ( string ) message affiché
function showMessage(type, message){
    var alert = $('<p>');
    alert.addClass('msg-info flash');
    alert.addClass(type);
    alert.html(message);

    $('body').append(alert);

    setTimeout(function(){
        alert.addClass('open');
    }, 100)

    setTimeout(function(){
        alert.removeClass('open');
    }, 3000)

    setTimeout(function(){
        alert.remove();
    }, 3500)
}

// ONGLETS
function afficheOnglet(element){
    if(changement){

        if(confirm('Si vous validez, vos modifications seront perdues'))
        {
            $('.tab-zone .tab-item').removeClass('open');
            $($(element).attr('data-id')).addClass('open');
            $('.tab-nav a').removeClass('actif');
            $(element).addClass('actif');
            changement = false ;
        }
    }

    else {
        $('.tab-zone .tab-item').removeClass('open');
        $($(element).attr('data-id')).addClass('open');
        $('.tab-nav a').removeClass('actif');
        $(element).addClass('actif');
    }
} // Affiche onglet

function hashchange( tag ){
     if(tag != '' ){
        $('.tab-zone .tab-item').removeClass('open');
        $(tag).addClass('open');
        $('.tab-nav a').removeClass('actif');
        $('.tab-nav a[data-id="'+tag+'"]').addClass('actif');
    }
}


$(function() {

    // RETOUR EN HAUT - créer balise #gotop
    $('#gotop').on('click',function() {  //
        $('html, body').animate({scrollTop:0}, 'slow');
        return false;
    });

    // BOUTON TOGGLE MAIN NAV
    $('.toggle-nav').on('click', function () {
        $(this).toggleClass('menu-open');
        $('.sidebar').toggleClass('open');
    });

    // BOUTON TOGGLE SEARCH
    $('.toggle-search').click(function(){
        $(this).toggleClass("search-mobile-on");
        $('.form-search').toggleClass("search-open");
        return false;
    });

    // POPUP
    $('*[data-popup]').click(function(){
        $('body').addClass('mode-popup');
        $('#'+$(this).attr('data-popup')).addClass('open');
    })

    // CLOSE POPUP
    $('.close-popup').click(function(){
        $('body').removeClass('mode-popup');
        $(this).parent('.popup').removeClass('open');
    })

    // ONGLETS
    $('*[data-onglet]').click(function(){
        $('.nav-onglet > a').removeClass('actif');
        $('.onglet-content').removeClass('open');
        $(this).addClass('actif');
        $('#'+$(this).attr('data-onglet')).addClass('open');
    })

    // TOGGLE SECTION
    $('.toggle-section > .toggle-item').on('click', '> .toggle-launcher', function () {
        if ($(this).parent('.toggle-item').hasClass("open")) {
            $(this).closest('.toggle-section').find('> .toggle-item.open > .section-content').hide('fast');
            $(this).closest('.toggle-section').find('> .toggle-item.open').removeClass('open');
        } else {
            $(this).closest('.toggle-section').find('> .toggle-item.open > .section-content').hide('fast');
            $(this).closest('.toggle-section').find('> .toggle-item.open').removeClass('open');
            $(this).parent('.toggle-item').addClass('open');
            $(this).next().show('fast').css('display', 'flex');
        }
        return false;
    });

    $('.btn-copy').click(function(){
        showMessage('valid', 'Element copié !') ;
    })

    // Flatpickr
    $(".flatpickr").flatpickr({
        locale: "fr",
        dateFormat: ('Y-m-d'),
        altInput: true,
        allowInput:true,
        altFormat : ('d/m/Y'),
        defaultDate : null
    });

    // PRISM
    Prism.highlightAll();

    $('.theme-switch .light').click(function() {
        var d = new Date();
        d.setTime(d.getTime() + (24*60*60*1000*360));
        document.cookie = 'theme=light; expires=' + d;
    })

    $('.theme-switch .dark').click(function() {
        var d = new Date();
        d.setTime(d.getTime() + (24*60*60*1000*360));
        document.cookie = 'theme=dark; expires=' + d;
    })

    //console.log(document.cookie);

}); // FIN DOCUMENT READY

function planning_RAZ(keep_raisonsociale) {
    $("div#conteneur_tag").html("");
    $("button.RAZ").addClass("inactif").removeClass("actif");
    if (!keep_raisonsociale) {
        $("#raison_sociale").val("");
    }
    $("#raison_sociale").focus();

}

function remplirProjetsEtTags() {
    /* clic sur un client => remplit les CHECKBOX des tags */
    $.post(
        "ajax/checkboxes_tag.php", {
            "id_client": $("#id_client").val()
        },
        function(data) {
            $("div#conteneur_tag").html(data);
        }
    );
}
